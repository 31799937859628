<template>
  <div v-show="showTable">
     
        <v-col>
          <v-card>
            <v-app-bar dark color="#476C9B">
              <v-icon large class="mr-3">data_usage</v-icon>
              <v-toolbar-title>User Profiling Stats</v-toolbar-title>
            </v-app-bar>
          </v-card>
        </v-col>

    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" lg="6" sm="12">
          <v-card class="mb-3">
            <v-app-bar dark color="#8280B3">
              <v-icon large class="mr-3">update_disabled</v-icon>
              <v-toolbar-title>One time</v-toolbar-title>
            </v-app-bar>
          </v-card>

          <table class="oneTable">
            <thead>
              <tr>
                <th style="width: 30%" scope="col">Profiling Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody
              v-for="[key, value] in Object.entries(oneTimeData)"
              :key="key._id"
            >
              <tr>
                <td class="bold">{{ key }}</td>
                <td>{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col cols="12" md="6" lg="6" sm="12">
          <v-card class="mb-3">
            <v-app-bar dark color="#5E5C99">
              <v-icon large class="mr-3">replay</v-icon>
              <v-toolbar-title>Recurring</v-toolbar-title>
            </v-app-bar>
          </v-card>

          <table>
            <thead>
              <tr>
                <th style="width: 30%" scope="col">Profiling Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody
              v-for="[key, value] in Object.entries(recurringData)"
              :key="key._id"
            >
              <tr>
                <td class="bold">{{ key }}</td>
                <td>{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import DataServiceVD from "./../services/dataServiceVD.js";
const dataServiceVD = new DataServiceVD();

export default {
  data() {
    return {
      oneTimeData: [],
      recurringData: [],
      showTable: false,
      dateCriteria: {},
      getMonthMenu: false,
      getMonth: null,
    };
  },

  mounted() {
    this.getUserStautus();
  },

  methods: {  
    async getUserStautus() {
      let data = {
        userId: this.$route.params.userId,
        projectCode: this.$route.params.projectCode
      }
      this.$setLoader();
      dataServiceVD.checkUserStatus(data).then((res) => {
        this.oneTimeData = res.data.oneTimeMap;
        this.recurringData = res.data.recurringMap;
        this.showTable = true;
        this.$disableLoader();
      });
    },

    async filterByDate() {
      let data = {
        month: this.dateCriteria.getMonth,
        userId: this.$route.params.userId,
        projectCode: this.$route.params.projectCode
      }

      this.$setLoader();
      dataServiceVD.filterByDate(data).then(res => {
        this.recurringData = res.data;
        this.$disableLoader();
      })
    }
  },
};
</script>

<style scoped lang="scss">
// @import "~bootstrap/scss/bootstrap.scss"; 

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}


.oneTable {
  height: 244px;
} 


</style>